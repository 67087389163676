import { useEffect, useRef } from "react";
// import * as Sentry from "@sentry/nextjs";
import axios from "axios";
import io from "socket.io-client";
import { Modal } from "antd";

import { SendRequest } from "../public/js/sendRequest";
import { useAppContext } from "../context/appContext";

function PushNotificationApp() {
  const initialRender = useRef(false);
  const {
    setMessageData,
    setScripterData,
    setActivecallkeyData,
    setCreditCardInfoData,
    cookies,
    removeCookie,
    setFranchiseName,
  } = useAppContext();
  const blockUserRef = useRef(false);

  const handleLogOut = async (cookies, removeCookie) => {
    try {
      const response = await axios({
        url: `https://${cookies.tenant}/api/`,
        method: "GET",
        params: {
          session: cookies.session,
          function: "logout",
        },
        timeout: 5000,
      });

      if (response.status == 200) {
        removeCookie("session");

        const databases = await window.indexedDB.databases();

        for (const database of databases) {
          window.indexedDB.deleteDatabase(database.name);
        }

        window.location.reload();
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const notifyExtensionError = (isSuccess, message) => {
    if (blockUserRef.current) return;
    if (message?.reload) {
      blockUserRef.current = true;
    }
    if (!isSuccess) {
      Modal.error({
        title: message?.title || "Warning: Quick Menu Plugin Inactive",
        content: (
          <div>
            <p>
              {message?.content ||
                "The Quick Menu plugin is currently closed, which may cause issues. Please transfer the call to store, restart your tools, and ensure that the Quick Menu plugin is open."}
            </p>
          </div>
        ),
        okText: message?.reload ? "Logout" : "OK",
        onOk: () => {
          if (message?.reload) {
            // Reload the current tab
            handleLogOut(cookies, removeCookie);
          } else {
            // Just close the modal, which will happen automatically if nothing is done here
            console.log("Modal closed without reloading.");
          }
        },
      });
    }
  };

  let agentName = cookies.email;

  useEffect(() => {
    if (initialRender.current) return;
    initialRender.current = true;

    let socket;

    try {
      const getTokenAndConnect = async () => {
        const { data } = await axios.post("/api/socketToken", {
          agentName,
          currentDate: Date.now(),
        });
        if (data.code !== 200) {
          console.log(data);
          return;
        }

        socket = io(
          process.env.NODE_ENV == "development"
            ? process.env.NEXT_PUBLIC_ENDPOINT_DEVELOPMENT
            : process.env.NEXT_PUBLIC_ENDPOINT_PRODUCTION,
          {
            transports: ["websocket"],
          }
        );

        socket.on("connect", () => {
          socket.emit(
            "subscribe",
            JSON.stringify({ token: data?.data?.token })
          );
          console.log("connected to server");
        });

        socket.on("connect_error", (connect_error) => {
          console.log("connect_error", connect_error);
          setTimeout(() => {
            socket.connect();
          }, 1000);
        });

        socket.on("boran-messages", async (event, callback) => {
          let messageData = JSON.parse(event);
          // console.log('boran-messagge', messageData);
          let sendResponse = { isSuccess: true };

          if (messageData && messageData.data && messageData.data.type) {
            if (messageData.data.type === "connected") {
              if (
                messageData.data.activeCallQueue === "CCS TEST Purposes Queue"
              ) {
                setFranchiseName("ChickenSalad");
              } else {
                setFranchiseName("Dominos");
              }
              messageData.data.customerPhoneMismatch = false;
              messageData.data.isMobile = true;
              messageData.data.contactId
                ? setActivecallkeyData(messageData.data.contactId)
                : console.log("contactId Not Found!");
              setMessageData(messageData.data);
              sendResponse = await SendRequest(messageData.data);
              notifyExtensionError(sendResponse.isSuccess);
              setScripterData((prev) => ({
                ...prev,
                connected: sendResponse?.isSuccess?.toString(),
              }));
            } else if (messageData.data.type === "disconnected") {
              setMessageData(messageData.data);
              sendResponse = await SendRequest(messageData.data);
              notifyExtensionError(sendResponse.isSuccess);
              setScripterData((prev) => ({
                ...prev,
                disconnected: sendResponse?.isSuccess?.toString(),
                activeCallQueue: messageData?.data?.activeCallQueue
              }));
            } else if (messageData.data.type == "destroyed") {
              setMessageData(messageData.data);
              sendResponse = await SendRequest(messageData.data);
              notifyExtensionError(sendResponse.isSuccess);
              setScripterData((prev) => ({
                ...prev,
                destroyed: sendResponse?.isSuccess?.toString(),
              }));
            }

            if (messageData.data.type === "scripterData") {
              console.log("Scripter Data Received!!!", messageData);
              // await setScripterData(messageData.data);
              await setScripterData((prev) => ({
                ...prev,
                ...messageData.data,
              }));
              const stringAddress = (messageData?.data?.stringAddress?.toLowerCase()?.includes("carryout") ? null : messageData?.data?.stringAddress?.split(",")?.[0])  || null;
              sendResponse = await SendRequest({
                type: "scripterData",
                contactId: messageData.data.contactId,
                agentName: messageData.data.agentName,
                customerName: messageData.data.name,
                int_name: messageData?.data?.int_name  || null,
                int_address: messageData?.data?.int_address  || null,
                stringAddress,
              });
              notifyExtensionError(sendResponse.isSuccess);
            }

            if (messageData.data.type === "payment-unsuccess-notification") {
              Modal.error({
                title: "Notification",
                content: (
                  <div>
                    <p>
                      Card information is not correct! Please check with the
                      customer
                    </p>
                  </div>
                ),
              });
            }

            if (messageData.data.type === "creditCardInfo") {
              await setCreditCardInfoData(messageData.data);
              sendResponse = await SendRequest(messageData.data);
              notifyExtensionError(sendResponse.isSuccess);
            }
          }
          const callbackMessage = {
            message: "boran-messages",
            type: messageData.data.type ? messageData.data.type : "",
            customerResponse: sendResponse,
          };
          callback(JSON.stringify(callbackMessage));
        });

        socket.on("server-events", async (event) => {
          console.log("server-events", event);
          if (
            event.code === 4003 ||
            event.message === "wrong or expired token!"
          ) {
            const tokenResponse = await axios.post("/api/socketToken", {
              agentName,
              currentDate: Date.now(),
            });
            socket.emit(
              "subscribe",
              JSON.stringify({ token: tokenResponse?.data?.data?.token })
            );
          }
        });

        socket.on("disconnected-reason", (event) => {
          console.log("disconnected-reason", event);
        });

        socket.on("disconnect", async (reason) => {
          console.log(`disconnect reason => ${reason}`);
        });
      };
      getTokenAndConnect();
    } catch (error) {
      console.log(error);
      // Sentry.captureMessage("Push Notification error", {
      //   level: "error",
      //   tags: {
      //     Description: "An error occurred while push notification.",
      //     "Agent Name": agentName,
      //     error: error,
      //   },
      // });
    }

    const pingAndNotify = async () => {
      try {
        const result = await SendRequest({ type: "pingExtension" });
        console.log(result);
        if (
          result?.isSuccess &&
          result?.version_collected &&
          result?.hasNewVersion &&
          result?.newVersion !== result?.currentVersion
        ) {
          notifyExtensionError(false, {
            title: "Extension Update Required",
            content:
              "Your Quick Menu extension is not up to date. Please close all open Chrome tabs and relaunch the extension. If the issue persists, please reboot your computer.",
            reload: true,
          });
        } else {
          notifyExtensionError(result.isSuccess);
        }
      } catch (error) {
        console.error("Error in pingExtensionm request:", error);
      }
    };

    pingAndNotify();
    const intervalPingId = setInterval(pingAndNotify, 300000);

    return () => {
      clearInterval(intervalPingId);
    };
  }, []);

  return <></>;
}

export { PushNotificationApp };
