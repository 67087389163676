import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Layout, Avatar, Tooltip, Modal, Alert, Row, Col, Button } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import styles from "../styles/HeaderApp.module.css";

import { useAppContext } from "../context/appContext";

const { Header } = Layout;

// const notifyMessages = {
//   notNotification:
//     "This browser does not support desktop notification! The FCM Messaging application will not be able to run without notifications allowed!",
//   granted: "Notifications allowed.",
//   denied:
//     "Notifications must be allowed for the FCM Messaging application to run! Please allow browser notifications.",
// };

const handleLogOut = async (cookies, removeCookie) => {
  try {
    const response = await axios({
      url: `https://${cookies.tenant}/api/`,
      method: "GET",
      params: {
        session: cookies.session,
        function: "logout",
      },
      timeout: 5000,
    });

    if (response.status == 200) {
      removeCookie("session");

      const databases = await window.indexedDB.databases();

      for (const database of databases) {
        window.indexedDB.deleteDatabase(database.name);
      }

      window.location.reload();
    }
  } catch (err) {
    console.error(err.message);
  }
};

// const successMessage = (content) => {
//   Modal.success({
//     title: "Notification Success",
//     content: (
//       <div>
//         <p>{content}</p>
//       </div>
//     ),
//     onOk() {},
//   });
// };

// const errorMessage = (content) => {
//   Modal.error({
//     title: "Error Notification",
//     content: (
//       <div>
//         <p>{content}</p>
//       </div>
//     ),
//   });
// };

export default function HeaderApp() {
  const { cookies, removeCookie, setOpenDTMFModal, activecallkeyData, showDTMFButton, setDtmfState, franchiseName } = useAppContext();
  const [errorDesc, setErrorDesc] = useState("");
  const [showError, setShowError] = useState("none");
  const sideEffectRef = useRef(false);

  // useEffect(() => {
  //   if (sideEffectRef.current) return;
  //   sideEffectRef.current = true;

  //   if (!("Notification" in window)) {
  //     errorMessage(notifyMessages.notNotification);
  //     setErrorDesc(notifyMessages.notNotification);
  //     setShowError("");
  //   }

  //   if (Notification.permission === "granted") {
  //     return;
  //   } else if (
  //     Notification.permission == "denied" ||
  //     Notification.permission == "default"
  //   ) {
  //     Notification.requestPermission()
  //       .then((permission) => {
  //         if (permission === "granted") {
  //           successMessage(notifyMessages.granted);
  //           setShowError("none");
  //           window.location.reload();
  //         } else if (permission == "denied" || permission == "default") {
  //           errorMessage(notifyMessages.denied);
  //           setErrorDesc(notifyMessages.denied);
  //           setShowError("");
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // }, []);

  async function showModal() {
    try {
      setOpenDTMFModal(true);
      const result = await axios({
        url: "/api/dtmf/get-dtmf-form",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          tenant: "eperformax-arria.callcenterstudio.com",
          session: cookies.session,
          activecallkey: activecallkeyData,
        },
        timeout: 7000
      });

      if (!(result.status == 200 && result?.data?.success)) {
        setOpenDTMFModal(false);
        axios({
          method: "POST",
          url: "/api/gcpLogger",
          data: {
            gcpData: {
              success: false,
              data: result?.data,
              status: result?.status || 408,
            },
            message: `productAPI/dtmfFormTrigger`
          }
        })  
      }

      let mappedData = result.data.data.form.fields.map((e) => {
        return {
          id: e.variable_name,
          label: e.title,
          type: e.input_type,
          editable:
            e.field_type === "user_input" || e.field_type === "text"
              ? false
              : true,
          static: e.field_type === "text",
          required: e.required,
          value: e.value,
          completed: null,
          valid: null,
          validText: null,
        };
      });

      setDtmfState((prev) => ({
        ...prev,
        open: true,
        data: mappedData,
        selectedInput: null,
        ack: activecallkeyData,
      }));


    } catch (err) {
      setOpenDTMFModal(false);
      console.error(err);
      console.log(err.message);

      axios({
        method: "POST",
        url: "/api/gcpLogger",
        data: {
          gcpData: {
            success: false,
            data: err?.response?.data,
            url: err?.config?.url,
            status: err?.response?.status || 408,
            message: err?.message
          },
          message: `productAPI/dtmfFormTrigger`
        }
      })  
    }
  }

  return (
    <div>
      <Header className={styles.headerContainer}>
        <div className={styles.logoContainer}>
          <img
            className={styles.companyLogo}
            style={{ marginLeft: "2px" }}
            src="images/epx-logo.png"
            alt="company logo"
          />
        </div>
        {
          (showDTMFButton && franchiseName === "Dominos") && <div>
          <Button onClick={showModal}>Customer Card Form</Button>
          </div>
        }
        <div className={styles.rightContainer}>
          <Tooltip placement="bottomLeft" title={cookies.email} color="gray">
            <Avatar className={styles.avatar} size="large">
              <h3>
                {cookies.email ? cookies.email.slice(0, 1).toUpperCase() : ""}
              </h3>
            </Avatar>
          </Tooltip>
          <Tooltip placement="bottomLeft" title="Sign Out" color="gray">
            <LogoutOutlined
              className={styles.logout}
              onClick={() => handleLogOut(cookies, removeCookie)}
            />
          </Tooltip>
        </div>
      </Header>
      <Row justify={"center"}>
        <Col xs={22} sm={23} md={20} lg={10}>
          <Alert
            message="Error Notification"
            description={errorDesc}
            type="error"
            showIcon
            className={styles.errorContainer}
            style={{ display: showError }}
          />
        </Col>
      </Row>
    </div>
  );
}
