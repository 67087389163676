import { Layout } from "antd";

import StepsApp from "./StepsApp";
import FormApp from "./FormApp";
import { useAppContext } from "@/context/appContext";
import ChickenSaladFormApp from "./ChickenSaladFormApp";

export default function ContentApp() {
  const { franchiseName, setFranchiseName } = useAppContext();
  return (
    <Layout style={{ backgroundColor: "#fff" }}>
      <StepsApp />
      {
        {
          "Dominos": <FormApp />,
          "ChickenSalad": <ChickenSaladFormApp />
        }[franchiseName]
      }
      
    </Layout>
  );
}
