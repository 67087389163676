import React, { useEffect } from 'react';
import { Modal, Table, Tooltip, Button } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { useAppContext } from '@/context/appContext';

const IntelTableModal = () => {
  const { isVisibleIntTable, setIsVisibleIntTable, scripterData, messageData, franchiseName } = useAppContext();

  let {
    int_store: store,
    int_phone: phone,
    int_name: name,
    int_address: address,
    int_orderType: orderType,
    int_summary: summary,
    int_utterance: utterance,
    int_callType: callType,
    int_paymentType: paymentType
  } = scripterData;

  useEffect(() => {
    if (scripterData?.["intelepeer-uui"] && messageData?.type === "connected" && franchiseName === "Dominos") {
      setIsVisibleIntTable(true)
    }
  }, [scripterData, messageData])

  const columns = [
    {
      title: 'Key',
      dataIndex: 'Key',
      key: 'Key',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: (text) => (
        <>
          {text}
         {
            text && 
            <Tooltip title="Copy to Clipboard">
            <Button 
              type="text"
              icon={<CopyOutlined />} 
              onClick={() => navigator.clipboard.writeText(text)} 
              style={{ marginLeft: 5, color: 'rgba(0, 0, 0, 0.45)' }}
            />
          </Tooltip>
         }
        </>
      ),
    },
  ];

  const exceptionValue = ["NULL", "Null", "null", null, undefined, "undefined"];

  const data = [
    { key: '1', Key: 'Name', value: exceptionValue.includes(name) ? "" : name },
    { key: '2', Key: 'Store ID', value: exceptionValue.includes(store) ? "" : store },
    { key: '3', Key: 'Phone', value: exceptionValue.includes(phone) ? "" : phone },
    { key: '4', Key: 'Address', value: exceptionValue.includes(address) ? "" : address },
    { key: '5', Key: 'Order Type', value: exceptionValue.includes(orderType) ? "" : orderType },
    { key: '6', Key: 'Summary', value: exceptionValue.includes(summary) ? "" : summary },
    { key: '7', Key: 'Utterance', value: exceptionValue.includes(utterance) ? "" : utterance },
    { key: '8', Key: 'Call Type', value: exceptionValue.includes(callType) ? "" : callType },
    { key: '9', Key: 'Payment Type', value: exceptionValue.includes(paymentType) ? "" : paymentType },
  ];

  const handleOk = () => {
    setIsVisibleIntTable(false);
  };

  const handleCancel = () => {
    setIsVisibleIntTable(false);
  };

  return (
    <>
      <Modal
        title="Call Info"
        visible={isVisibleIntTable}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
      >
        <Table columns={columns} dataSource={data} />
      </Modal>
    </>
  );
};

export default IntelTableModal;
